import { Typography } from "@mui/material";
import React, { FC, Fragment, ReactNode, useEffect, useState } from "react";
import short from "short-uuid";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { reactStringReplace } from "utills";
import { Maybe, TagType } from "api";

interface TextPostProps {
  text: Maybe<string> | undefined;
  onMentionedTagTypeClick?: any;
  requrieSeeMore?: boolean;
}

const TextPost: FC<TextPostProps> = ({
  text,
  onMentionedTagTypeClick,
  requrieSeeMore,
}) => {
  text = (text || "") as string;
  const textLength = text?.length;
  const calculationObject = FindText(text, 150, 5);
  const [slice, setSlice] = useState(
    text.length > 150 || lineBreakCount(text) > calculationObject?.line
      ? calculationObject?.char
      : 150
  );

  useEffect(() => {
    setSlice(
      (text as string).length > 150 ||
        lineBreakCount(text) > calculationObject?.line
        ? calculationObject?.char
        : 150
    );
  }, [text, setSlice]);

  const handleSeeMore: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.preventDefault();
    if (slice === textLength) {
      setSlice(calculationObject?.char);
    } else {
      setSlice(textLength);
    }
  };

  return (
    <CustomTypography variant="body1">
      {getReadableText(
        requrieSeeMore ? text.slice(0, slice + 1) : text,
        onMentionedTagTypeClick
      )}
      {text.length > slice &&
        calculationObject?.char + 1 !== text.length &&
        requrieSeeMore && (
          <Fragment>
            ... <SeeMore onClick={handleSeeMore}>See more</SeeMore>
          </Fragment>
        )}
    </CustomTypography>
  );
};

export default TextPost;

const CustomTypography = styled(Typography)`
  white-space: pre-wrap;
  font-size: 0.938rem;
  font-weight: 400;
  line-height: 1.375rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
`;

const SeeMore = styled.span`
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

export const lineBreakCount = (text) => {
  try {
    return text?.match(/[^\n]*\n[^\n]*/gi).length;
  } catch (e) {
    return 0;
  }
};
export const FindText = (text: string, count: number, reqLine: number) => {
  let flag = 0;
  let extra = 0;
  let flag1 = 0;
  let cou = 0;
  let i = 0;
  let link = 0;
  let line = 0;
  const totalLines = lineBreakCount(text);
  if (text.length > 150 || totalLines > reqLine) {
    while (count >= 0 && i < text.length - 1) {
      if (text[i]?.match(/[^\n]*\n[^\n]*/gi)) {
        line++;
        if (line >= reqLine) {
          i -= 1;
          break;
        }
      }
      if (text.length >= i + 2) {
        if (
          text[i] === "[" &&
          (text[i + 1] === "$" || text[i + 1] === "@" || text[i + 1] === "#")
        ) {
          flag = 1;
          // console.log("trueeeee", i, flag1, cou)
        }
      }
      if (flag === 0) {
        count--;
      }
      if (flag === 1 && text[i] === "]") {
        flag = 0;
        // console.log("falsssss", i, flag1, cou)
      }
      if (flag) {
        if (text[i] === "$" || text[i] === "@" || text[i] === "#") {
          flag1 = 1;
        } else if (flag1) {
          cou++;
          if (count < cou) {
            break;
          }
        }
        if (text[i] === ":" && flag1 === 1) {
          flag1 = 0;
          count -= cou;
          cou = 0;
        }
      }
      if (text.length - 1 >= i + 7) {
        if (
          text.slice(i, i + 8) === "https://" ||
          text.slice(i, i + 7) === "http://"
        ) {
          console.log("link");
          link++;
        }
      }
      if (link) {
        if (text[i] === " ") {
          link = 0;
        }
      }
      i++;
    }
    // console.log(flag, i, count)
    let j = i;
    if (flag === 1) {
      while (j < text.length - 1) {
        if (text[j] === "]") {
          extra++;
          break;
        }
        j++;
        extra++;
      }
    } else if (link !== 0) {
      while (j < text.length - 1) {
        if (text[j] === " ") {
          extra++;
          break;
        }
        j++;
        extra++;
      }
    }
  } else {
    return { char: text.length, line: line };
  }
  return { char: i + extra, line: line };
};

export const getReadableText = (
  text: string,
  onMentionedTagTypeClick?: any
): Array<ReactNode> => {
  let replacedText: Array<ReactNode> = reactStringReplace(
    text,
    /(https?:\/\/\S+)/gi,
    (match: string, i: number) => (
      <MentionLink
        key={short.generate()}
        href={match}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => {
          e.preventDefault();
          window.open(match, "_blank");
        }}
      >
        {match}
      </MentionLink>
    )
  );

  // Match users
  replacedText = reactStringReplace(
    replacedText,
    /\[(@[^:]+[^\]]+)\]/gi,
    (match: string, i: number) => {
      const [display, param] = match.split(":");
      const username = param.split("/").shift();
      const to = `/profile/${username}`;
      return (
        <MentionUserLink
          key={short.generate()}
          to={to}
          replace={window.location.pathname.includes(to)}
          onClick={() => onMentionedTagTypeClick(TagType?.UserTag)}
        >
          {display}
        </MentionUserLink>
      );
    }
  );

  // Match stocktag
  replacedText = reactStringReplace(
    replacedText,
    /\[(\$[^:]+[^\]]+)\]/gi,
    (match: string, i: number) => {
      const [display, param] = match.split(":");
      const paramsLower = param.toLocaleLowerCase();
      let to = `/stocktag/${paramsLower}`;
      if (
        paramsLower.includes("stock/") ||
        paramsLower.includes("sector/") ||
        paramsLower.includes("industry/")
      ) {
        to = `/${paramsLower}`;
      }
      return (
        <MentionStockTagLink
          key={short.generate()}
          to={to}
          replace={window.location.pathname.includes(to)}
          onClick={() =>
            onMentionedTagTypeClick(TagType?.DollarTag, paramsLower)
          }
        >
          {display}
        </MentionStockTagLink>
      );
    }
  );

  // Match hashtags
  replacedText = reactStringReplace(
    replacedText,
    /\[(#[^:]+[^\]]+)\]/gi,
    (match: string, i: number) => {
      const [display, param] = match.split(":");
      const to = `/hashtag/${param}`;
      return (
        <MentionHashTagLink
          key={short.generate()}
          to={to}
          replace={window.location.pathname.includes(to)}
          onClick={() => onMentionedTagTypeClick(TagType?.HashTag)}
        >
          {display}
        </MentionHashTagLink>
      );
    }
  );

  return replacedText;
};

const MentionLink = styled.a`
  text-decoration: none;
  color: #1b6ac9;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const MentionUserLink = styled(Link)`
  text-decoration: none;
  color: rgb(27, 149, 224);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const MentionHashTagLink = styled(Link)`
  text-decoration: none;
  color: #1b6ac9;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const MentionStockTagLink = styled(Link)`
  text-decoration: none;
  color: rgb(58 105 186);
  font-weight: 600;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
