import React from "react";
import MoreMenu from "components/MoreMenu";
import { Button, ButtonProps, MenuItem } from "@mui/material";
import { Maybe, Post, PostShareTypePermissions } from "api";
import {
  ActionTypes,
  useCreateAndSharePostContext,
} from "features/create-and-share-post";

type ShareButtonProps = {
  sharePostId: Maybe<string> | undefined;
  postSharePermissions: Maybe<Maybe<PostShareTypePermissions>[]> | undefined;
} & Omit<ButtonProps, "onClick">;

const ShareButton: React.FC<React.PropsWithChildren<ShareButtonProps>> = ({
  sharePostId,
  postSharePermissions,
  children,
  ...props
}) => {
  const [, dispatch] = useCreateAndSharePostContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSharePost = () => {
    setAnchorEl(null);
    dispatch?.({
      type: ActionTypes.OPEN_POST_SHARE_MODAL,
      sharePostId: sharePostId as string,
    });
  };

  const handleUpdateStockIdeaPost = () => {
    setAnchorEl(null);
    dispatch?.({
      type: ActionTypes.OPEN_STOCK_IDEA_SHARE_MODAL,
      sharePostId: sharePostId as string,
    });
  };

  const menus: Array<React.ReactNode> = [];

  if (postSharePermissions?.includes(PostShareTypePermissions.NormalShare)) {
    menus.push(
      <MenuItem onClick={handleSharePost} disableRipple dense>
        Share Post
      </MenuItem>
    );
  }

  if (
    postSharePermissions?.includes(
      PostShareTypePermissions.StockIdeaUpdateShare
    )
  ) {
    menus.push(
      <MenuItem onClick={handleUpdateStockIdeaPost} disableRipple dense>
        Update on Stock Idea
      </MenuItem>
    );
  }

  if (postSharePermissions?.length === 0) return null;
  return (
    <React.Fragment>
      <Button
        aria-label="Share"
        id={`btnShare}`}
        color="inherit"
        onClick={handleClick}
        {...props}
      >
        <ShareIcon />
        {children}
      </Button>
      <MoreMenu anchorEl={anchorEl} handleClose={handleClose}>
        {menus}
      </MoreMenu>
    </React.Fragment>
  );
};

export default ShareButton;

const ShareIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="18" cy="5" r="3"></circle>
    <circle cx="6" cy="12" r="3"></circle>
    <circle cx="18" cy="19" r="3"></circle>
    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
  </svg>
);
