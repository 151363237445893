import React, { useRef, useState, useEffect, PropsWithChildren } from "react";
import { Link, Typography, styled } from "@mui/material";
import { getReadableText } from "utills";
import { Maybe } from "api";

const TruncatedText: React.FC<PropsWithChildren<{ text?: Maybe<string> }>> = ({
  text = "",
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        console.log(
          "textRef.current.scrollHeight",
          textRef.current.scrollHeight,
          "textRef.current.clientHeight",
          textRef.current.clientHeight
        );
        const isOverflowing =
          textRef.current.scrollHeight > textRef.current.clientHeight;
        setIsOverflowing(isOverflowing);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => window.removeEventListener("resize", checkOverflow);
  }, [text]);

  return (
    <TextContainer>
      <Text
        ref={textRef}
        className={`text ${isExpanded ? "expanded" : "truncated"}`}
      >
        {getReadableText(text || "")}
      </Text>
      {isOverflowing && (
        <MoreLessLink onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? "...(less)" : "...(more)"}
        </MoreLessLink>
      )}
    </TextContainer>
  );
};

export default TruncatedText;

const MoreLessLink = styled(Link)({
  position: "absolute",
  bottom: 0,
  right: 0,
  cursor: "pointer",
  textDecoration: "underline",
  backgroundColor: "#fff"
});

const TextContainer = styled("div")({
  position: "relative",
});

const Text = styled(Typography)({
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxHeight: "calc(1.5rem * 3)",
  "&.expanded": {
    display: "block",
    maxHeight: "100%",
  },
});
