import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Badge,
} from "@mui/material";
import React, { FC } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { BookmarkIcon, HomeIcon } from "icons";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import {
  useHasPendingUnderApprovalRequestsQuery,
  useMeQuery,
  useShowEventsDotQuery,
} from "api";
import { reference } from "../../pages/Home";
import { sendEvent, GAEvent } from "ga4";

const MenuList: FC = () => {
  const { data } = useShowEventsDotQuery();
  const { data: meData } = useMeQuery();
  const { data: pendingCount } = useHasPendingUnderApprovalRequestsQuery();

  const handleRefetchFeed = () => {
    sendEvent({ event: "web_left_menu_clicked", button_name: "Home" });
    (reference?.current as any)?.refetch();
  };

  const handleSendEvent = (data: GAEvent) => () => {
    sendEvent(data);
  };

  console.log(pendingCount?.hasPendingUnderApprovalRequests);

  return (
    <CustomList dense>
      <ListItem disableGutters disablePadding data-tut="reactour__home">
        {location.pathname.includes("/home") ? (
          <ListItemButton disableGutters onClick={handleRefetchFeed}>
            <ListItemAvatar>
              <HomeIcon />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ variant: "h2" }}
              primary="Home"
            />
          </ListItemButton>
        ) : (
          <ListItemButton
            disableGutters
            component={NavLink}
            to="/home"
            // activeClassName="active"
            data-tut="reactour__home"
            onClick={handleSendEvent({
              event: "web_left_menu_clicked",
              button_name: "Home",
            })}
          >
            <ListItemAvatar>
              <HomeIcon />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ variant: "h2" }}
              primary="Home"
            />
          </ListItemButton>
        )}
      </ListItem>
      <ListItem disableGutters disablePadding>
        <ListItemButton
          disableGutters
          component={NavLink}
          data-tut="reactour__explore"
          to="/explore"
          // activeClassName="active"
          onClick={handleSendEvent({
            event: "web_left_menu_clicked",
            button_name: "Discover",
          })}
        >
          <ListItemAvatar>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path
                d="M4.34125 9.03275C1.9475 9.03275 0 10.9802 0 13.374C0 15.7679 1.9475 17.7152 4.34125 17.7152C6.735 17.7152 8.6825 15.7679 8.6825 13.374C8.6825 10.9802 6.735 9.03275 4.34125 9.03275ZM4.34125 16.4196C2.66199 16.4196 1.2959 15.0533 1.2959 13.374C1.2959 11.6947 2.66199 10.3286 4.34125 10.3286C6.02051 10.3286 7.3866 11.6947 7.3866 13.374C7.3866 15.0535 6.02051 16.4196 4.34125 16.4196Z"
                fill="currentColor"
              />
              <path
                d="M6.54664 2.32518C5.83325 2.20163 5.11231 2.36342 4.51704 2.78155C3.92696 3.19557 3.53258 3.81026 3.4045 4.51436L0.223558 12.064C0.0846912 12.3941 0.23934 12.7738 0.569144 12.9129C0.899183 13.052 1.27907 12.8969 1.41797 12.5673L4.6577 4.87831C4.69289 4.79452 4.7093 4.70682 4.70844 4.62042C4.90133 3.92581 5.59829 3.47616 6.32571 3.60206C6.69762 3.66643 7.02313 3.87378 7.24235 4.18628C7.4618 4.49901 7.54711 4.87698 7.4825 5.25042C7.47688 5.28366 7.47364 5.31737 7.47321 5.35108L7.3436 13.8824C7.33821 14.2403 7.62371 14.5349 7.98161 14.5403C7.98485 14.5403 7.98809 14.5403 7.99153 14.5403C8.34465 14.5403 8.63387 14.2567 8.63926 13.9023L8.76797 5.41827C8.99137 3.95842 8.00496 2.57745 6.54664 2.32518Z"
                fill="currentColor"
              />
              <path
                d="M11.879 8.68695H8.09933V9.98285H11.879V8.68695Z"
                fill="currentColor"
              />
              <path
                d="M11.879 10.9334H8.09933V12.2293H11.879V10.9334Z"
                fill="currentColor"
              />
              <path
                d="M15.6587 9.03253C13.265 9.03253 11.3175 10.98 11.3175 13.3738C11.3175 15.7677 13.265 17.7153 15.6587 17.7153C18.0525 17.7153 20 15.7679 20 13.3738C20 10.98 18.0525 9.03253 15.6587 9.03253ZM15.6587 16.4196C13.9795 16.4196 12.6134 15.0533 12.6134 13.374C12.6134 11.6947 13.9797 10.3286 15.6587 10.3286C17.3378 10.3286 18.7041 11.6947 18.7041 13.374C18.7041 15.0535 17.338 16.4196 15.6587 16.4196Z"
                fill="currentColor"
              />
              <path
                d="M19.7764 12.0641L16.5957 4.51548C16.3296 3.05154 14.9239 2.07076 13.4531 2.32521C12.7393 2.44876 12.1153 2.84529 11.6968 3.44185C11.2896 4.0224 11.1253 4.7222 11.2319 5.4183L11.3607 13.9023C11.3661 14.2567 11.655 14.5403 12.0084 14.5403C12.0116 14.5403 12.0151 14.5403 12.0187 14.5406C12.3764 14.5352 12.662 14.2406 12.6568 13.8827L12.5271 5.35134C12.5265 5.31763 12.5232 5.28396 12.5177 5.25068C12.4528 4.87705 12.5382 4.49908 12.7576 4.1863C12.9768 3.87376 13.3023 3.66642 13.6743 3.60208C14.4002 3.47662 15.0982 3.92564 15.2915 4.61978C15.2904 4.70638 15.3069 4.79431 15.3423 4.8783L18.582 12.5673C18.7211 12.8973 19.101 13.0515 19.4308 12.9129C19.7607 12.7738 19.9155 12.3939 19.7764 12.0641Z"
                fill="currentColor"
              />
              <path
                d="M11.9006 4.71307H8.09933V6.00897H11.9006V4.71307Z"
                fill="currentColor"
              />
            </svg>
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{ variant: "h2" }}
            primary="Discover"
          />
        </ListItemButton>
      </ListItem>
      <ListItem disableGutters disablePadding>
        <ListItemButton
          disableGutters
          component={NavLink}
          to="/bookmarks"
          // activeClassName="active"
          onClick={handleSendEvent({
            event: "web_left_menu_clicked",
            button_name: "Bookmark",
          })}
        >
          <ListItemAvatar>
            <BookmarkIcon />
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{ variant: "h2" }}
            primary="Bookmarks"
          />
        </ListItemButton>
      </ListItem>
      <ListItem disableGutters disablePadding>
        <ListItemButton
          disableGutters
          component={NavLink}
          to="/learn"
          // activeClassName="active"
          data-tut="reactour__learn"
          onClick={handleSendEvent({
            event: "web_left_menu_clicked",
            button_name: "Learn",
          })}
        >
          <ListItemAvatar>
            <Badge
              badgeContent={Boolean(data?.showEventsDot) ? <BlueDot /> : null}
            >
              <LearnIcon />
            </Badge>
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{ variant: "h2" }}
            primary="Learn"
          />
        </ListItemButton>
      </ListItem>

      {meData?.me.hasUnderApprovalRequests && (
        <ListItem disableGutters disablePadding>
          <ListItemButton
            disableGutters
            component={NavLink}
            to={`/profile/${meData.me.username}/under-approval-posts`}
          >
            <ListItemAvatar>
              <Badge
                badgeContent={pendingCount?.hasPendingUnderApprovalRequests}
                color="error"
              >
                <PlaylistAddCheckIcon />
              </Badge>
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ variant: "h2" }}
              primary="Under Approval"
            />
          </ListItemButton>
        </ListItem>
      )}
    </CustomList>
  );
};

const LearnIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 408 306"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M393.121 80.1489L230.291 5.72788C213.575 -1.90929 194.438 -1.90929 177.722 5.72788L14.8793 80.1489C5.69925 84.3563 0 93.243 0 103.354C0 113.464 5.69925 122.351 14.8793 126.558L76.5 154.723V231.923C76.5 259.323 93.5723 283.497 118.996 292.077C146.574 301.321 175.172 306 204 306C232.828 306 261.426 301.321 289.03 292.077C314.428 283.509 331.5 259.335 331.5 231.923V154.723L357 143.069V216.751C357 223.789 362.699 229.501 369.75 229.501C376.801 229.501 382.5 223.789 382.5 216.751V131.416L393.121 126.558C402.301 122.364 408 113.477 408 103.366C408 93.2557 402.301 84.3436 393.121 80.1489ZM306 231.923C306 248.383 295.902 262.842 280.908 267.903C230.979 284.631 176.996 284.618 127.118 267.903C112.098 262.842 102 248.371 102 231.923V166.376L177.709 200.979C186.061 204.792 195.024 206.704 204 206.704C212.976 206.704 221.927 204.804 230.291 200.979L306 166.376V231.923ZM219.67 177.787C209.712 182.339 198.275 182.339 188.318 177.787L25.4108 103.379L25.4872 103.341L188.33 28.9199C198.288 24.3682 209.725 24.3682 219.682 28.9199L382.513 103.366L219.67 177.787Z"
      fill="currentColor"
    />
  </svg>
);
export default MenuList;

const BlueDot = styled.div`
  width: 12px;
  height: 12px;
  background: #1da1f3;
  border: 2px solid #fafafa;
  border-radius: 50%;
`;

const CustomList = styled(List)`
  .MuiListItemButton-root {
    padding-left: 24px;
  }
  .MuiListItemAvatar-root {
    min-width: 44px;
    align-items: center;
    display: flex;
    position: relative;
  }
  a:hover {
    color: #007aff;
    .MuiListItemIcon-root {
      color: #007aff;
    }
    .MuiTypography-root {
      color: #007aff;
    }
  }
`;
