import { ActionTypes, Actions, State } from "./types";

export function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case ActionTypes.OPEN_POST_MODAL: {
      const { clubId, isAudienceChangeAble = true } = action;
      return {
        ...state,
        modal: "POST",
        clubId,
        isAudienceChangeAble,
      };
    }

    case ActionTypes.OPEN_QUERY_MODAL: {
      const { clubId, isAudienceChangeAble = true } = action;
      return {
        ...state,
        modal: "ASK_QUERY",
        clubId,
        isAudienceChangeAble,
      };
    }

    case ActionTypes.OPEN_EDIT_QUERY_MODAL: {
      const { postId } = action;
      return {
        ...state,
        modal: "EDIT_ASK_QUERY",
        postId,
      };
    }

    case ActionTypes.OPEN_STOCK_IDEA_MODAL: {
      const { clubId, isAudienceChangeAble = true } = action;
      return {
        ...state,
        modal: "SHARE_STOCK_IDEA",
        clubId,
        isAudienceChangeAble,
      };
    }

    case ActionTypes.OPEN_EDIT_STOCK_IDEA_MODAL: {
      const { postId } = action;
      return {
        ...state,
        modal: "EDIT_SHARE_STOCK_IDEA",
        postId,
      };
    }

    case ActionTypes.OPEN_POST_SHARE_MODAL: {
      const { clubId, sharePostId, isAudienceChangeAble = true } = action;
      return {
        ...state,
        modal: "SHARE_POST",
        clubId,
        sharePostId,
        isAudienceChangeAble,
      };
    }

    case ActionTypes.OPEN_STOCK_IDEA_SHARE_MODAL: {
      const { sharePostId } = action;
      return {
        ...state,
        modal: "UPDATE_SHARE_STOCK_IDEA",
        sharePostId,
      };
    }

    case ActionTypes.EDIT_POST: {
      const {
        postId,
        clubId,
        sharePostId,
        isAudienceChangeAble = true,
      } = action;
      return {
        ...state,
        modal: "EDIT_POST",
        postId,
        clubId,
        sharePostId,
        isAudienceChangeAble,
      };
    }

    case ActionTypes.SHARED_POSTS: {
      const { postId } = action;
      return {
        ...state,
        modal: "SHARED_POSTS",
        postId,
      };
    }

    case ActionTypes.EDITED_POST_HISTORIES: {
      const { postId } = action;
      return {
        ...state,
        modal: "EDITED_POST_HOSTORIES",
        postId,
      };
    }

    case ActionTypes.MEDIA_OF_POSTS: {
      const { userId } = action;
      return {
        ...state,
        modal: "MEDIA_OF_POSTS",
        userId,
      };
    }

    case ActionTypes.CLOSE_MODAL:
      return {
        ...state,
        modal: undefined,
        clubId: undefined,
        sharePostId: undefined,
        isAudienceChangeAble: true,
      };

    default:
      return state;
  }
}
