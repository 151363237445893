import { styled } from "@mui/material";
import { TagType } from "api";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { reactStringReplace } from "utills";

export const getReadableText = (
  text: string,
  onMentionedTagTypeClick?: any
): Array<ReactNode> => {
  let replacedText: Array<ReactNode> = reactStringReplace(
    text,
    /(https?:\/\/\S+)/gi,
    (match: string, i: number) => (
      <MentionLink
        key={match}
        href={match}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => {
          e.preventDefault();
          window.open(match, "_blank");
        }}
      >
        {match}
      </MentionLink>
    )
  );

  // Match users
  replacedText = reactStringReplace(
    replacedText,
    /\[(@[^:]+[^\]]+)\]/gi,
    (match: string, i: number) => {
      const [display, param] = match.split(":");
      const username = param.split("/").shift();
      const to = `/profile/${username}`;
      return (
        <MentionUserLink
          key={match}
          to={to}
          replace={window.location.pathname.includes(to)}
          onClick={() => onMentionedTagTypeClick(TagType?.UserTag)}
        >
          {display}
        </MentionUserLink>
      );
    }
  );

  // Match stocktag
  replacedText = reactStringReplace(
    replacedText,
    /\[(\$[^:]+[^\]]+)\]/gi,
    (match: string, i: number) => {
      const [display, param] = match.split(":");
      const paramsLower = param.toLocaleLowerCase();
      let to = `/stocktag/${paramsLower}`;
      if (
        paramsLower.includes("stock/") ||
        paramsLower.includes("sector/") ||
        paramsLower.includes("industry/")
      ) {
        to = `/${paramsLower}`;
      }
      return (
        <MentionStockTagLink
          key={match}
          to={to}
          replace={window.location.pathname.includes(to)}
          onClick={() =>
            onMentionedTagTypeClick(TagType?.DollarTag, paramsLower)
          }
        >
          {display}
        </MentionStockTagLink>
      );
    }
  );

  // Match hashtags
  replacedText = reactStringReplace(
    replacedText,
    /\[(#[^:]+[^\]]+)\]/gi,
    (match: string, i: number) => {
      const [display, param] = match.split(":");
      const to = `/hashtag/${param}`;
      return (
        <MentionHashTagLink
          key={match}
          to={to}
          replace={window.location.pathname.includes(to)}
          onClick={() => onMentionedTagTypeClick(TagType?.HashTag)}
        >
          {display}
        </MentionHashTagLink>
      );
    }
  );

  return replacedText;
};

const MentionLink = styled("a")`
  text-decoration: none;
  color: #1b6ac9;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const MentionUserLink = styled(Link)`
  text-decoration: none;
  color: rgb(27, 149, 224);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const MentionHashTagLink = styled(Link)`
  text-decoration: none;
  color: #1b6ac9;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const MentionStockTagLink = styled(Link)`
  text-decoration: none;
  color: rgb(58 105 186);
  font-weight: 600;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
